html {
    overflow-x: hidden; 
}

body {
    background-color: #000d1c;
    justify-content: space-between;
    height: 100%;
    overflow-x: hidden;
    position: relative;
  }

.footer {
      overflow: initial;
      position: initial;
  }

.plone-nav a {
    color: #ffffff;
}

.header {
    position: absolute;
}

#portal-logo img {
    filter: brightness(0) invert(1);
}

.svg-container {
    @media screen and (max-width: 768px) {
        margin-left: calc(50% - 960px);
        min-width: 1350px;
    } 
}

.audio-controller-wrapper {
    position: absolute;
    right: 10%;
    top: 50%;
    z-index: 100;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        display: none;
    } 
    &.action-play {
        .bar {
            animation: none;
            animation-name: none;
        }
  }
    .audio-controller {
        overflow: hidden;
        height: 30px;
        width: 40px;
        margin: 0 auto;
        position: relative;
      }

    .bar{
        height: 1px;
        width: 3px;
        display: inline-block;
        background-color: #fff;
        bottom: 0;
        position: absolute;
        &.bar--1 { 
          left: 0; 
          animation-name: danceHeight1;
          animation-delay: 0ms;
        }
        &.bar--2 { 
          left: 6px; 
          animation-name: danceHeight2; 
          animation-delay: 300ms;
        }
        &.bar--3 { 
          left: 12px;
          animation-name: danceHeight3;
          animation-delay: 600ms;
        }
        &.bar--4 { 
          left: 18px;
          animation-name: danceHeight4;
          animation-delay: 900ms;
        }
        &.bar--5 { 
          left: 24px;
          animation-name: danceHeight5;
          animation-delay: 1200ms;
        }
        &.bar--6 { 
          left: 30px;
          animation-name: danceHeight6;
          animation-delay: 1500ms;
        }
        animation-duration: 500ms;
        animation-play-state: running;
        animation-direction: alternate;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
      
      @keyframes danceHeight1 {
        from { height: 1px; }
        to { height: 22px; }
      }
      @keyframes danceHeight2 {
        from { height: 1px; }
        to { height: 15px; }
      }
      @keyframes danceHeight3 {
        from { height: 1px; }
        to { height: 30px; }
      }
      @keyframes danceHeight4 {
        from { height: 1px; }
        to { height: 20px; }
      }
      @keyframes danceHeight5 {
        from { height: 1px; }
        to { height: 15px; }
      }
      @keyframes danceHeight6 {
        from { height: 1px; }
        to { height: 15px; }
      }
}

.error-container {
    color: #e3e4e5;
    font-family: Lato;
    display: flex;
    flex-direction: column;
    align-items: center;  

    @media screen and (max-width: 1400px){
        padding-top: 20vh;
    }  

    @media screen and (max-width: 768px){
        padding-top: 0;
    }   
}
 
.error--error404 {
    font-size: 60px;
    font-weight: 400;
    margin-bottom: 25px;
    @media screen and (max-width: 768px) {
        font-size: 50px;
    }  
    @media screen and (max-width: 480px) {
        font-size: 30px;
    }  
}

.error--not-found {
    font-size: 24px;
    font-weight: 300;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }  
    @media screen and (max-width: 480px) {
        font-size: 16px;
    }  
}

.lighting-container {
    position:absolute;
    width:200px;
    height:200px;
    top: 590px;
    left: 45%;
    animation:bigBoxAnimation 100s linear infinite;
    z-index: 1;
    @media screen and (max-width: 480px) {
        left: 10%;
        top: 450px;
    }  
  }

.lighting {
    position:absolute;
    top:60%;
    left:50%;
    width:40px;
    height:40px;
    margin:-20px 0 0 -20px;
  }
  
.lighting--one {
    animation:boxAnimation 1.2s cubic-bezier(0.02, 0.01, 0.31, 1) infinite;
    transform:matrix(-1,0,1,1,0,0);
  }
.lighting--two {
    animation:boxAnimation 3.2s cubic-bezier(0.02, 0.01, 0.31, 1) infinite;
    transform:matrix(-1,0,0,1,0,0);
  }

.lighting::after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:6px;
    height:6px;
    background:#fff;
    border-radius:100%;
    animation:spanAnimation 3s cubic-bezier(0.3, 0.27, 0.07, 1.64) infinite;
    box-shadow:rgba(255,255,255,1) 0 0 20px 2px;
  }
  @keyframes bigBoxAnimation {
    100% {transform:rotate(360deg);}
  }
  @keyframes boxAnimation {
    50% {transform:translateY(-150px);}
  }
  @keyframes spanAnimation {
    50% {transform:translateX(-150px)}
  }


.birds-container {
    position: absolute;
    width: 100%;
    top: 85px;
    z-index: 1;
}

.bird {
    background-image: url("../images/bird-cells.svg");
    background-size: auto 100%;
    width: 88px;
    height: 125px;
    will-change: background-position;
    animation-duration: 1s;
    animation-name: fly-cycle;
    animation-timing-function: steps(10);
    animation-iteration-count: infinite;
    transform: scale(0.85);
}

.bird--one {
    animation-delay: -0.5s;
}

.bird--two {
    animation-delay: -0.75s;
    transform: scale(.5);
}

.bird--three {
    animation-delay: -1s;
    transform: scale(.7);
}

.bird--four {
    animation-delay: -1.25s;
}

.bird--five {
    animation-delay: -1.5;
}

.bird--six {
    animation-delay: -1.75s;
    transform: scale(.6);
}

.bird--seven {
    animation-delay: -2s;
    transform: scale(.5);
}

.bird--eight {
    animation-delay: -2.25s;
    transform: scale(.6);
}

.bird--nine {
    animation-delay: -2.5;
}

.bird--ten {
    animation-delay: -2.75s;
}

.bird--eleven {
    animation-delay: -3s;
    transform: scale(.6);
}

.bird--twelve {
    animation-delay: -3.25s;
    transform: scale(.75);
}


.bird-container:nth-child(even) {
    @media screen and (max-width: 768px) {
        display: none;
    } 
}

.bird-container {
    position: absolute;
    top: 20%;
    left: -7.5vw;
    transform: scale(0);
    will-change: transform;
    animation-name: fly-right-one;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    @media screen and (max-width: 480px) {
        animation-duration: 15s;
    }  
}

.bird-container--one {
    animation-delay: 2s;
    animation-duration: 25s;
}

.bird-container--two {
    animation-delay: 3.14s;
    animation-duration: 26s;
}

.bird-container--three {
    animation-delay: 5s;
    animation-duration: 26s;
}

.bird-container--four {
    animation-delay: 5.2s;
    animation-duration: 27s;
}

.bird-container--five {
    animation-delay: 7.6s;
    animation-duration: 26s;
}

.bird-container--six {
    animation-delay: 9s;
    animation-duration: 25s;
}

.bird-container--seven {
    animation-delay: 11.4s;
    animation-duration: 27s;
}

.bird-container--eight {
    animation-delay: 13.8s;
    animation-duration: 25s;
}

.bird-container--nine, {
    animation-delay: 15.2s;
    animation-duration: 25s;
}

.bird-container--ten {
    animation-delay: 18.6s;
    animation-duration: 25s;
}

.bird-container--eleven {
    animation-delay: 22s;
    animation-duration:25s;
}

.bird-container--twelve {
    animation-delay: 24.4s;
    animation-duration: 26s;
}


 @keyframes fly-cycle {
 100% {
 background-position: -900px 0;
 }
}

@keyframes fly-right-one {
0% {
    left: -10%;
    transform: scale(0.3);
}
10% {
left: 10%;
transform: translateY(2vh) scale(0.4);
}
20% {
left: 30%;
transform: translateY(0vh) scale(0.5);
}
30% {
left: 50%;
transform: translateY(4vh) scale(0.6);
}
40% {
left: 70%;
transform: translateY(2vh) scale(0.6);
}
50% {
left: 90%;
transform: translateY(0vh) scale(0.6);
}
60% {
left: 110%;
transform: translateY(0vh) scale(0.6);
}
100% {
left: 110%;
opacity: 1;
transform: translateY(0vh) scale(0.6);
}
}

@keyframes fly-right-two {
 0% {
 left: -10%;
 opacity: 1;
 transform: translateY(-2vh) scale(0.5);
}
 10% {
 left: 10%;
 transform: translateY(0vh) scale(0.4);
}
 20% {
 left: 30%;
 transform: translateY(-4vh) scale(0.6);
}
 30% {
 left: 50%;
 transform: translateY(1vh) scale(0.45);
}
 40% {
 left: 70%;
 transform: translateY(-2.5vh) scale(0.5);
}
 50% {
 left: 90%;
 transform: translateY(0vh) scale(0.45);
}
 51% {
 left: 110%;
 transform: translateY(0vh) scale(0.45);
}
 100% {
 left: 110%;
 transform: translateY(0vh) scale(0.45);
}
}

/* Circle */
.circle-container {
    height: 133px;
    width: 133px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 35vh;
    right: 50%;
    z-index: -1;
    box-shadow: 10px 10px 192px 153px #ffffff2b;
    @media screen and (max-width: 1700px) {
        top: 25vh;
    }
    @media screen and (max-width: 768px) {
        top: 20vh;
    }
    @media screen and (max-width: 480px) {
        top: 15vh;
    } 
}

.circle-container::after {
    content:'ops!';
    position:absolute;
    font-family: Lato;
    top: 70px;
    left: 130px;
    color: #e3e4e5;
    font-size: 130px;
    font-weight: 400;
    @media screen and (max-width: 768px) {
        font-size: 100px;
    }
    @media screen and (max-width: 480px) {
        font-size: 60px;
    }     
}


@isPlone:false;
@isPlone: false;